@use '@/styles/utils/mixins.scss' as *;

.otherNumbersWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .otherNumbersBox {
    background-color: $white;
    border-radius: 1.25rem;
    padding: 2rem 2.5rem;
    .otherNumbersList {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.5rem;
      text-transform: uppercase;
      @include tab() {
        flex-direction: column;
        gap: 1.5rem;
      }
      li {
        display: flex;
        align-items: center;
        gap: 2.5rem;

        @include tab() {
          flex-direction: column;
          gap: 1.5rem;
          align-items: flex-start;
          width: 100%;
        }
        &:not(:last-child) {
          &::after {
            content: '';
            background-color: $primaryAlt;
            width: 0.063rem;
            height: 5.5rem;
            @include tab() {
              height: 0.063rem;
              width: 100%;
            }
          }
        }
      }
      .otherNumbersListItem {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        flex: 1 1 auto;
        @include tab() {
          gap: 0.5rem;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }
        .otherNumbersListTitle {
          font-size: 1rem;
          color: $primary;
          font-weight: 400;
          max-width: 9rem;
          @include tab() {
            max-width: 100%;
            br {
              display: none;
            }
          }
        }
        .otherNumbersListContact {
          font-size: 2.125rem;
          line-height: 1;
          color: $dark;
          font-weight: 400;
          transition: all 0.2s ease-in-out;
          text-wrap: nowrap;
          @include tab() {
            font-size: 1.5rem;
          }
        }
        a.otherNumbersListContact {
          &:hover,
          &:focus,
          &:active {
            color: $primaryAlt;
          }
        }
      }
    }
  }
}
